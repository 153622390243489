
import request from '@/axios'



export function mpLogin(obj) {
  return request({
    url: '/api/weixin/api/ma/wxuser/mp-login',
    method: 'post',
    data: obj
  })
}

export function vipOrder(obj) {
  return request({
    url: '/api/mall/api/ma/userinfo/vipOrder',
    method: 'post',
    data: obj
  })
}

