/**
 * 全站http配置
 *
 * header参数说明
 * serialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from "axios";


// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
const request = axios.create({
  baseURL: 'https://saas.crystal-map.com',
  timeout: 30 * 1000, // 超时时间 单位是ms，这里设置了3s的超时时间
  validateStatus: function (status) {
    //返回其他状态码
    return status >= 200 && status <= 500; // 默认的
  },
});


// HTTPrequest拦截
request.interceptors.request.use(
    (config) => {
        config.headers["app-id"] =localStorage.getItem("app-id")
        config.headers["tenant-id"] = '1623969689556488192'
        config.headers["third-session"] = localStorage.getItem('third-session');
        return config;
    },
    (error) => {
      return Promise.reject(error);
    })

export default request;
