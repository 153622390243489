import { vipOrder } from "@/api/wxuser";
import { Toast } from "vant";
import 'vant/lib/index.css';
import wx from 'weixin-jsapi';
export default {
  created() {
    if (this.$route.query.grade) {
      this.pay(parseInt(this.$route.query.grade));
    }
  },
  data() {
    return {
      payParams: {}
    };
  },
  methods: {
    pay(grade) {
      // 获取微信jsapi支付参数的api的接口
      let that = this;
      vipOrder({
        userGrade: grade
      }).then(res => {
        if (res.data.code === 0) {
          let {
            appId,
            nonceStr,
            timeStamp,
            paySign
          } = res.data.data;
          let prepayId = res.data.data.packageValue;
          wx.config({
            debug: false,
            // 测试阶段可用 true 打包返回给后台用 false
            appId: appId,
            timestamp: timeStamp,
            nonceStr: nonceStr,
            signature: paySign,
            jsApiList: ['chooseWXPay']
          });
          wx.ready(function () {
            wx.chooseWXPay({
              appId: appId,
              timestamp: timeStamp,
              // 时间戳
              nonceStr: nonceStr,
              // 随机字符串
              package: prepayId,
              // 统一支付接口返回的prepay_id参数值
              signType: 'MD5',
              //  签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: paySign,
              // 支付签名
              success: function (res) {
                that.Toast('支付成功');
              },
              cancel: function (res) {
                that.Toast('支付取消');
              },
              fail: function (res) {
                that.Toast('支付失败');
              }
            });
          });
        } else {
          localStorage.removeItem('open-id');
          location.reload();
        }
      }).catch(e => {
        console.log(e);
        Toast({
          message: '失败'
        });
        localStorage.removeItem('open-id');
        location.reload();
      });
    },
    Toast(msg) {
      Toast({
        message: msg
      });
      if (window.WeixinJSBridge) {
        window.WeixinJSBridge.call('closeWindow'); // 微信，只是微信就用这个API。
      }
    }
  }
};